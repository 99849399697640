export const toggle = {
    primary: {
        active: 'border-amber-700 bg-amber-900',
        inactive: 'border-amber-900 bg-black'
    },
    secondary: {
        active: 'border-amber-800 bg-amber-950',
        inactive: 'border-amber-900 bg-black'
    },
    danger: {
        active: 'border-red-700 bg-red-900',
        inactive: 'border-red-900 bg-black'
    },
    success: {
        active: 'border-emerald-700 bg-emerald-900',
        inactive: 'border-emerald-900 bg-black'
    },
    disabled: {
        active: 'border-amber-900 bg-amber-950',
        inactive: 'border-amber-900 bg-black'
    },
    toggle: 'bg-amber-500'
}

export default toggle